import React, { useEffect,useState,onFormSubmit,startDate, } from 'react'
import metamask from '../../assets/images/wallet/metamask.png';
import alpha from '../../assets/images/wallet/alpha.png';
import binance from '../../assets/images/wallet/binance.png';
import bitski from '../../assets/images/wallet/bitski.png';
import coinbase from '../../assets/images/wallet/coinbase.png';
import enjin from '../../assets/images/wallet/enjin.png';
import formatic from '../../assets/images/wallet/formatic.png';
import pionex from '../../assets/images/wallet/pionex.png';
import torus from '../../assets/images/wallet/torus.png';
import HomeKBS from '../../assets/images/bg/bg1.jpg';
import RoyalBackground from '../../assets/images/background-royal.jpg'
import trustwallet from '../../assets/images/wallet/trustwallet.png';
import connect from '../../assets/images/wallet/wallet-connect.png';
import Navbar from '../../components/navbar'
import image from '../../assets/images/image.jpg';
import slider from '../../assets/images/home-slider1.jpg';
import slider1 from '../../assets/images/home-slider38.jpg';
import slider2 from '../../assets/images/home-slider3.jpg';
import slider3 from '../../assets/images/home-slider4.jpg';
import slider4 from '../../assets/images/home-slider39.jpg';
import slider5 from '../../assets/images/home-slider41.jpg';
import slider6 from '../../assets/images/home-slider40.jpg';
import slider7 from '../../assets/images/home-slider2.jpg';
import slider8 from '../../assets/images/home-slider9.jpg';
import slider9 from '../../assets/images/home-slider10.jpg';
import slider10 from '../../assets/images/home-slider11.jpg';
import slider11 from '../../assets/images/home-slider37.jpg';
import slider12 from '../../assets/images/home-slider32.jpg';
import slider13 from '../../assets/images/home-slider14.jpg';
import slider14 from '../../assets/images/home-slider33.jpg';
import slider15 from '../../assets/images/home-slider20.jpg';
import slider16 from '../../assets/images/home-slider34.jpg';
import slider17 from '../../assets/images/home-slider30.jpg';
import slider18 from '../../assets/images/home-slider29.jpg';
import slider19 from '../../assets/images/home-slider28.jpg';
import slider20 from '../../assets/images/home-slider36.jpg';
import slider21 from '../../assets/images/home-slider35.jpg';
import image1 from '../../assets/images/image1.jpg';
import image2 from '../../assets/images/image2.png';
import image3 from '../../assets/images/image3.png';
import image4 from '../../assets/images/image4.png';
import logo_white from '../../assets/images/logo-white.png';
import FooterNew from '../../components/footer-new';
import Switcher from '../../components/switcher';
import { Link } from 'react-router-dom';
import {MdOutlineArrowForward} from "../../assets/icons/vander"
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Testimonial from './testimonial';
// import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import addDays from 'date-fns/addDays';
import "react-datepicker/dist/react-datepicker.css";
import Gallery1 from '../../assets/images/wedding-r.jpg';
import Gallery2 from '../../assets/images/bussiness-r.webp';
import Gallery3 from '../../assets/images/private-r.jpeg';
import Gallery4 from '../../assets/images/corporate-r.jpg';
import Gallery5 from '../../assets/images/social-r.jpg';
import Gallery6 from '../../assets/images/gallery6.jpg';
import Gallery7 from '../../assets/images/birthday-r.jpg';
import Gallery8 from '../../assets/images/gallery8.jpg';
import Gallery9 from '../../assets/images/gallery9.jpg';
import Gallery10 from '../../assets/images/gallery10.jpg';
import Gallery11 from '../../assets/images/gallery11.jpg';
import Gallery12 from '../../assets/images/gallery12.jpg';
import Gallery13 from '../../assets/images/gallery13.jpg';
import Gallery14 from '../../assets/images/gallery14.jpg';
import Gallery15 from '../../assets/images/gallery15.jpg';
import Gallery16 from '../../assets/images/gallery16.jpg';
import Gallery17 from '../../assets/images/gallery17.jpg';
import Gallery18 from '../../assets/images/gallery18.jpg';
import Gallery19 from '../../assets/images/gallery19.jpg';
import Gallery20 from '../../assets/images/gallery20.jpg';
import Gallery21 from '../../assets/images/gallery21.jpg';
import Gallery22 from '../../assets/images/gallery22.jpg';
import Gallery23 from '../../assets/images/gallery23.jpg';
import Gallery24 from '../../assets/images/gallery24.jpg';
import Gallery25 from '../../assets/images/gallery25.jpg';
import Gallery26 from '../../assets/images/gallery26.jpg';
import Gallery27 from '../../assets/images/gallery27.jpg';
import Royal1 from '../../assets/images/royal-1.jpg';
import Royal2 from '../../assets/images/royal-2.jpg';
import Royal3 from '../../assets/images/royal-3.png';
import Royal4 from '../../assets/images/royal-4.png';
import Royal5 from '../../assets/images/royal-5.png';
import KBS from '../../assets/images/royal-1.jpg';
import feedback1 from '../../assets/images/royal-2.jpg';
import feedback2 from '../../assets/images/royal-3.png'
import feedback3 from '../../assets/images/royal-4.png'
import {MdKeyboardArrowLeft, MdKeyboardArrowRight,PiBrowsersLight, MdOutlineSportsVolleyball, AiOutlinePieChart, LuMusic4, TbCameraPlus, PiRocketLaunchBold} from "../../assets/icons/vander"

const images = [
    { id: 1, src: Gallery1, name: 'Wedding',category: 'Images', },
    { id: 2, src: Gallery2, name: 'Business',category: 'Images', },
    { id: 3, src: Gallery3, name: 'Private',category: 'Images', },
    { id: 4, src: Gallery4, name: 'Corporate',category: 'Images', },
    { id: 5, src: Gallery5, name: 'Social',category: 'Images', },
    // { id: 6, src: Gallery6, name: 'Image 6',category: 'Images', },
    { id: 7, src: Gallery7, name: 'Birthday', category: 'Images', },
    // { id: 8, src: Gallery8, name: 'Image 8', category: 'Images', },
    // { id: 9, src: Gallery9, name: 'Image 9',category: 'Images', },
    // { id: 10, src:Gallery10, name: 'Image 10', category: 'Images', },
    // { id: 11, src: Gallery11, name: 'Image 11', category: 'Images', },
    // { id: 12, src: Gallery12, name: 'Image 12', category: 'Images', },
    // { id: 13, src: Gallery13, name: 'Image 13', category: 'Images', },
    // { id: 14, src: Gallery14, name: 'Image 14', category: 'Images', },
    // { id: 15, src: Gallery15, name: 'Image 15', category: 'Images', },
    // { id: 16, src: Gallery16, name: 'Image 16', category: 'Images', },
    // { id: 17, src: Gallery17, name: 'Image 17', category: 'Images', },
    // { id: 18, src: Gallery18, name: 'Image 18', category: 'Images', },
    // { id: 20, src: Gallery20, name: 'Image 20', category: 'Images', },
    // { id: 21, src: Gallery21, name: 'Image 21', category: 'Images', },
    // { id: 22, src: Gallery22, name: 'Image 22', category: 'Images', },
    // { id: 23, src: Gallery23, name: 'Image 23', category: 'Images', },
    // { id: 24, src: 'https://www.youtube.com/embed/C0DPdy98e4c', name: 'Image 11', category: 'Videos' },
    // { id: 25, src: Gallery25, name: 'Image 25', category: 'Images', },
    // { id: 26, src: Gallery26, name: 'Image 26', category: 'Images', },
    // { id: 27, src: Gallery27, name: 'Image 27', category: 'Images', },
    // { id: 28, src: Gallery28, name: 'Image 28', category: 'Images', },
    // { id: 29, src: Gallery29, name: 'Image 29', category: 'Images', },
    // { id: 30, src: Gallery30, name: 'Image 30', category: 'Images', },
    // { id: 31, src: Gallery31, name: 'Image 31', category: 'Images', },
    // { id: 32, src: Gallery32, name: 'Image 32', category: 'Images', },
];

export default function Home() {

    const [lightboxMedia, setLightboxMedia] = useState({ type: '', src: '' });
    const [isLightboxOpen, setIsLightboxOpen] = useState(false);

    const showLightbox = (media) => {
        setLightboxMedia(media);
        setIsLightboxOpen(true);
        document.body.style.overflow = 'hidden';
    };
    

    const closeLightbox = () => {
        setIsLightboxOpen(false);
        document.body.style.overflow = 'auto';
    };

    const [selectedCategory, setSelectedCategory] = useState(null);

    const matchCategory = (category) => {
        setSelectedCategory(category);
    };

    const filteredData = selectedCategory
        ? images.filter((item) => item.category === selectedCategory)
        : images;

    const image7 = 'https://media.istockphoto.com/id/1366428092/photo/webinar-e-learning-skills-business-internet-technology-concepts-training-webinar-e-learning.jpg?s=612x612&w=0&k=20&c=mJeYiZyGQXLXXZ6AWKbLwQDiuLr-YLRGV4VjHKdX0pM='

    const [activeIndex, setIndex] = useState(0);
    const [placeBid , setPlaceBid] = useState(false)
    const [ buyNow, setBuyNow] =  useState(false)

    const Partners = [
        {
            image: Royal1
        },
        {
            image: Royal2
        },
        {
            image: Royal3
        },
        {
            image: Royal4
        },
        {
            image: Royal5
        },

    ]
    const walletData = [
        { title: 'MetaMask', image: metamask },
        { title: 'Alpha', image: alpha },
        { title: 'Binance', image: binance },
        { title: 'Bitski', image: bitski },
        { title: 'Coinbase', image: coinbase },
        { title: 'Enjin', image: enjin },
        { title: 'Formatic', image: formatic },
        { title: 'Pionex', image: pionex },
        { title: 'Torus', image: torus },
        { title: 'Trustwallet', image: trustwallet },
        { title: 'Wallet Connect', image: connect },
    ];

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 5, 
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1000,
        arrows: false,
        dots: false,
        responsive: [
            {
                breakpoint: 1024, 
                settings: {
                    slidesToShow: 3, 
                    gap:10
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3, 
                    gap:10
                },
            },
            {
                breakpoint: 580,
                settings: {
                    slidesToShow: 2, 
                    gap:10
                },
            },
        ],
    };

 
  const [startDate, setStartDate] = useState(new Date());

  const handleChange = (date) => {
    setStartDate(date);
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    console.log(startDate);
  };
  const slides = [
    { id: 1, image: slider, alt: "Slider 1" },
    { id: 2, image: slider1, alt: "Slider 2" },
    { id: 3, image: slider2, alt: "Slider 3" },
    { id: 4, image: slider3, alt: "Slider 4" },
    { id: 5, image: slider4, alt: "Slider 5" },
    { id: 6, image: slider5, alt: "Slider 6" },
    { id: 7, image: slider6, alt: "Slider 7" },
    { id: 8, image: slider7, alt: "Slider 8" },
    { id: 9, image: slider8, alt: "Slider 9" },
    { id: 10, image: slider9, alt: "Slider 10" },
    { id: 11, image: slider10, alt: "Slider 11" }
  ];
  const slides1 = [
    { id: 1, image: slider11, alt: "Slider 12" },
    { id: 2, image: slider12, alt: "Slider 13" },
    { id: 3, image: slider13, alt: "Slider 14" },
    { id: 4, image: slider14, alt: "Slider 15" },
    { id: 5, image: slider15, alt: "Slider 16" },
    { id: 6, image: slider16, alt: "Slider 17" },
    { id: 7, image: slider17, alt: "Slider 18" },
    { id: 8, image: slider18, alt: "Slider 19" },
    { id: 9, image: slider19, alt: "Slider 20" },
    { id: 10, image: slider20, alt: "Slider 21" },
    { id: 11, image: slider21, alt: "Slider 22" }
  ];

   const studentFeedbacks = [
    {
        id:1,
        img: feedback3,
        videoLink:"gL2w09deRGA",
    },
    {
        id:2,
        img: feedback2,
        videoLink:"g-UU1eMfbz0",
    },
    {
        id:3,
        img: feedback1,
        videoLink:"GrIt0UW2iyk",
    },
    {
        id:4,
        img:KBS,
        videoLink:"_orvfS6mfuo",
    },
    ]

    return (
        <>
            <Navbar />
            <section className="vc_custom_1554365010982 relative w-full py-367">

                {/* <div className="absolute inset-0 bg-gradient-to-b from-transparent to-slate-900"></div> */}
                <div className="container">
                <div className="grid grid-cols-1" >
                    
                    <h4 className='font-bold slider-head-2'>TREAT IN ROYAL STYLE</h4>
                    <h4 className="slider-head-1">Since 2010</h4>
                    <p className='slider-para'>We are a full service event planning company and caterer.From weddings to office lunches, we do it all.</p>
                </div>
                </div>
            </section>
            
            {/* <div className="relative">
                <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div> */}
            

            <section className="relative pt-24 overflow-hidden" style={{ marginTop: '-100px' }}>
              <h4 className="slider-head-3">Our Timeline</h4> 
               <div className="container-fluid relative">
                 <div className="grid grid-cols-1">
                   <div className="slider relative overflow-hidden m-auto mb-4 before:content-[''] before:absolute before:top-0 before:start-0 before:z-2 after:content-[''] after:absolute after:top-0 after:end-0 after:z-2">
                     <div className="slide-track flex items-center">
                       {slides.map(slide => (
                         <div key={slide.id} className="slide h-auto md:w-[360px] w-72 mx-2">
                           <div className="group relative overflow-hidden rounded-lg shadow dark:shadow-gray-800">
                             <img style={{width:'100%',objectFit:'cover',height:'200px'}} src={slide.image} className="rounded-lg shadow-md dark:shadow-gray-700 group-hover:scale-110 transition-all duration-500" alt={slide.alt} />
                           </div>
                         </div>
                       ))}
                     </div>
                   </div>
                 </div>
               </div>
               <div className="container-fluid relative">
                 <div className="grid grid-cols-1">
                   <div className="slider2 relative overflow-hidden m-auto mb-4 before:content-[''] before:absolute before:top-0 before:start-0 before:z-2 after:content-[''] after:absolute after:top-0 after:end-0 after:z-2">
                     <div className="slide-track flex items-center">
                       {slides1.map(slide => (
                         <div key={slide.id} className="slide h-auto md:w-[360px] w-72 mx-2">
                           <div className="group relative overflow-hidden rounded-lg shadow dark:shadow-gray-800">
                             <img style={{width:'100%',objectFit:'cover',height:'200px'}} src={slide.image} className="rounded-lg shadow-md dark:shadow-gray-700 group-hover:scale-110 transition-all duration-500" alt={slide.alt} />
                           </div>
                         </div>
                       ))}
                     </div>
                   </div>
                 </div>
               </div>
            </section>

            {/* <section className="relative md:py-24 py-16">
                <div className="container">
                    <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[30px]">
                        {
                            walletData.map((ele, index) => (
                                <div key={index} className="group relative rounded-xl bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 p-6 text-center mt-10 hover:shadow-md dark:hover:shadow-gray-800 transition-all duration-500">
                                    <div className="relative -mt-16">
                                        <img src={ele.image} className="bg-white dark:bg-slate-900 h-20 w-20 rounded-full shadow-md dark:shadow-gray-800 mx-auto p-3" alt="" />
                                        <h5 className="text-lg font-semibold mt-5">{ele.title}</h5>
                                        <p className="text-slate-400 mt-3">Learn about how to get the wallet and much more clicking <Link to="/" data-modal-toggle="loginform" className="btn btn-link text-violet-600 hover:text-violet-600 after:bg-violet-600 duration-500 ease-in-out inline-flex items-center">here <MdOutlineArrowForward className="ms-1 text-base"/></Link></p>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </section> */}
            <section>
            <h4 className="slider-head-3">About Us</h4>
            <section className='mt-24' >
                 <div className='container p-6' style={{marginTop:'-65px'}}>
                     <div className=" p-6 glass-container">
                     <div className="container">
                     <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 gap-[30px]" >
                         <div className="lg:col-span-5 pt-2">
                         <div className="relative ">
                                <div className="absolute inset-0 bg-gradient-to-r from-red-600 to-red-600 rounded-lg -mt-[16px] -ms-[16px] h-[98%] w-[98%] -z-1"></div>
                                <iframe className='iframe-youtube2' src={`https://www.youtube.com/embed/l81EsEGEhos?si=PtujzlUqrM919kIg`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen="true"></iframe>

                            </div>
                             {/* <img src={image7} className="rounded-md shadow dark:shadow-gray-700 w-full h-auto" alt="" /> */}
                             
                         </div>
 
                         <div className="lg:col-span-7 lg:ms-8">
                             {/* <h5 className="md:text-2xl text-xl font-semibold">"Start your own online education"</h5> */}
 
                             <p className=" mt-4" style={{color:"#000000"}}>Royal catering and events is a division of royal group which was founded by Sir.P.V.Hamsa about 50 years back.All along royal catering and events has innovated the kerala market with unique spins on classic dishes,event design and presentations.We excel in listening to our customer’s ideas and objectives and creating customised menus and event plans for executing even the most ambitious signature styles.</p>
                             <br />
                             <button type="submit" id="submit" name="send"><Link to="/about-us" className="btn bg-transparent hover:bg-violet-600 border-violet-600 text-violet-600 hover:text-white rounded-full ms-2" style={{display:"flex", alignItems: "center" }}>Read More<MdOutlineArrowForward className="ms-1"/></Link></button>
                             {/* <h5 className="md:text-2xl text-xl ">"Call Our Helpdesk"</h5> */}
                             {/* <p className="text-slate-400 mt-4">79026 20001</p> */}
                         </div>
                     </div>
            

                 </div>
                     </div>
                     
                 </div> 
             </section>
            </section>

            

            <section className="relative md:py-24 py-16">
             <h4 className="slider-head-3"style={{marginBottom:"20px",marginTop:"20px"}}>Menus</h4>
                <div className="container"style={{marginTop:"-25px"}}>
                    <div className="grid grid-cols-1 items-center gap-[30px]">
                        <div className="filters-group-wrap text-center">
                            <div className="filters-group">
                        
                            </div>
                        </div>
                    </div>

                    {/* <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 mt-4 gap-[30px]">
                        {
                            filteredData.map((item, index) => (
                                <div key={index} className="picture-item shuffle-item shuffle-item--visible" data-groups='["games"]'>
                                    <div className="group relative overflow-hidden p-2 rounded-lg bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:shadow-md dark:shadow-md hover:dark:shadow-gray-700 transition-all duration-500 hover:-mt-2 h-fit">
                                        <div className="relative overflow-hidden">
                                            <div className="relative overflow-hidden rounded-lg">
                                                <img src={item.image} className="rounded-lg shadow-md dark:shadow-gray-700 group-hover:scale-110 transition-all duration-500" alt="" />
                                            </div>

                                            <div className="absolute -bottom-20 group-hover:bottom-1/2 group-hover:translate-y-1/2 start-0 end-0 mx-auto text-center transition-all duration-500">
                                                <Link to={`/item-detail/${item.id}`} className="btn btn-sm rounded-full bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white"><i className="mdi mdi-lightning-bolt"></i> Buy Now</Link>
                                            </div>

                                            <div className="absolute top-2 end-2 opacity-0 group-hover:opacity-100 transition-all duration-500">
                                                <Link to="/" className="btn btn-icon btn-sm rounded-full bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white"><i className="mdi mdi-plus"></i></Link>
                                            </div>
                                        </div>

                                        <div className="mt-3">
                                            <div className="flex items-center">
                                                <img src={item.avatar} className="rounded-full h-8 w-8" alt="" />
                                                <Link to={`/creator-profile/${item.id}`} className="ms-2 text-[15px] font-medium text-slate-400 hover:text-violet-600">{item.subtext}</Link>
                                            </div>

                                            <div className="my-3">
                                                <Link to={`/item-detail/${item.id}`} className="font-semibold hover:text-violet-600">{item.title}</Link>
                                            </div>

                                            <div className="flex justify-between p-2 bg-gray-50 dark:bg-slate-800 rounded-lg shadow dark:shadow-gray-700">
                                                <div>
                                                    <span className="text-[16px] font-medium text-slate-400 block">Price</span>
                                                    <span className="text-[16px] font-semibold block"><i className="mdi mdi-ethereum"></i> 3.5 ETH</span>
                                                </div>

                                                <div>
                                                    <span className="text-[16px] font-medium text-slate-400 block">Highest Bid</span>
                                                    <span className="text-[16px] font-semibold block"><i className="mdi mdi-ethereum"></i> 3.55 ETH</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div> */}
                </div>
            </section>
            
            <div className={`lightbox`}>
                <div className="lightbox-overlay">
                    <div className="lightbox-content">
                        {lightboxMedia.type === 'image' && (
                            <div className="preview-img">
                                {/* <div className="img-container">
                                    <img src={lightboxMedia.src} alt="preview-img" className="lightbox-image" />
                                    <i className="close-icon uil uil-times" onClick={closeLightbox}></i>
                                </div> */}
                            </div>
                        )}
                        {/* {lightboxMedia.type === 'video' && (
                            <div className="video-container iframe-youtube" style={{marginLeft:'35px'}}>
                                <iframe
                                    title="video"
                                    className='iframe-youtube'
                                    src={lightboxMedia.src}
                                    frameBorder="0"
                                    allowFullScreen = "true"
                                ></iframe>
                                <i className="close-icon uil uil-times" style={{marginLeft:'30px'}} onClick={closeLightbox}></i>
                            </div>
                        )} */}

                    </div>
                </div>
            </div>
          

            <section className="gallery" style={{ marginTop: '-60px' }}>
               <ul className="images">
                   {filteredData.map((media, index) => (
                       <li className="img" key={index} style={{ position: 'relative' }}>
                           {media.category === 'Videos' ? (
                               <button className="video" onClick={() => showLightbox({ type: 'video', src: media.src })}>
                                   <div className="relative">
                                       <img src={Gallery24} alt={media.name} />
                                       <div className='absolute inset-0 flex items-center justify-center text-black hover-overlay'>
                                           <i className="logos--youtube-icon" style={{ fontSize: '35px' }}></i>
                                       </div>
                                   </div>
                               </button>
                           ) : (
                               <div className="relative">
                                   <img src={media.src} alt={media.name} />
                                   <div className='absolute inset-0 flex items-center justify-center text-black hover-overlay  md:leading-snug leading-snug font-semibold'style={{fontSize:"200px"}}>
                                       <span>{media.name}</span>
                                   </div>
                               </div>
                           )}
                       </li>
                   ))}
               </ul>
         </section>

             
                <section className="relative" style={{marginBottom:'400px',marginTop:'80px'}}>
                    <h4 className="slider-head-5">Testimonial</h4>
                    <h4 className=" slider-head-7"style={{color:"#444444"}}>OUR CUSTOMERS</h4>
                    <section className="relative">
                   <div className="container" style={{display:'flex',justifyContent:'center',}}>
                       <div className="grid lg:grid-cols-2 md:grid-cols-1 grid-cols-1 mt-8 gap-[30px]"
                       >
                           {studentFeedbacks.map((student, index) => (
                               <div key={index}>
                                   <div className="relative iframe-youtube1" style={{background:'#000'}}>
                                       <iframe className='iframe-youtube1' src={`https://www.youtube.com/embed/${student.videoLink}?si=T7LbMebx6ftK9dmN`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen="true"></iframe>
                                   </div>
                               </div>
                           ))}
   
                       </div>
                   </div>
                </section>

                </section>
   
                <section>
                  <Testimonial/>
                </section>
            

            <section className="relative table w-full py-368 bg-cover" style={{ backgroundImage: `url(${RoyalBackground})`,backgroundSize: 'cover', backgroundPosition: 'center' ,marginTop:"60px"}}>
               
                <div className="absolute inset-0 bg-gradient-to-b from-transparent to-slate-900"></div>
                <div className="container" style={{backdropFilter: 'blur(4px)',borderRadius:'6px',marginBottom:"30px",marginTop:"-30px"}}>
                <div className="grid grid-cols-1 text-center"  >
                 <h4 className="slider-head-3"style={{marginBottom:"10px"}}>Reservation</h4>
                 <h4 className=" slider-head-4"style={{color:"#fff"}}>Book Your Event</h4>
                <form onSubmit={onFormSubmit}method="post" name="myForm" id="myForm" >
                                        <p className="mb-0" id="error-msg"></p>
                                        <div id="simple-msg"></div>
                                        <div className="grid lg:grid-cols-12 lg:gap-6">
                                            <div className="lg:col-span-6 mb-5">
                                                <div className="text-start">
                                                    <label htmlFor="name" className="font-semibold">Your Name:</label>
                                                    <input id="name" type="text" className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent rounded-full focus:border-violet-600  dark:focus:border-violet-600 focus:ring-0 mt-2" style={{background:'tranparent',border:'1px solid #fff'}} placeholder="Name :" />
                                                </div>
                                            </div>

                                            <div className="lg:col-span-6 mb-5">
                                                <div className="text-start">
                                                    <label htmlFor="email" className="font-semibold">Mobile Number:</label>
                                                    <input id="phone" type="phone" className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent rounded-full focus:border-violet-600 dark:focus:border-violet-600 focus:ring-0 mt-2" style={{background:'tranparent',border:'1px solid #fff'}} placeholder="Phone :" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="grid lg:grid-cols-12 lg:gap-6">
                                            <div className="lg:col-span-6 mb-5">
                                            <div>
                                                <div className="text-start" style={{display:'flex', flexDirection:'column'}}>
                                                   <label htmlFor="email" className="font-semibold">Calendar:</label>
                                                   <DatePicker 
                                                     selected={startDate}
                                                     className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent1 rounded-full focus:border-violet-600 dark:focus:border-violet-600 focus:ring-0 mt-2"
                                                     style={{background:'transparent', border:'1px solid #fff'}}
                                                     placeholder="Select a date:"
                                                     onChange={handleChange}
                                                     showTimeSelect
                                                     timeFormat="HH:mm"
                                                     timeIntervals={20}
                                                     timeCaption="time"
                                                     dateFormat="MMMM d, yyyy h:mm aa"
                                                     minDate={new Date()}
                                                     maxDate={addDays(new Date())}
                                                     value={startDate} // Display the selected date and time in the textbox
                                                   />
                                                </div>
                                            </div>

                                            </div>

                                            <div className="lg:col-span-6 mb-5">
                                                <div className="text-start">
                                                    <label htmlFor="email" className="font-semibold">No. Of Person:</label>
                                                    <input id="phone" type="number" className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent rounded-full focus:border-violet-600 dark:focus:border-violet-600 focus:ring-0 mt-2" style={{background:'tranparent',border:'1px solid #fff'}} placeholder="No :" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="grid grid-cols-1">
                                            <div className="mb-5">
                                                <div className="text-start">
                                                    <label htmlFor="subject" className="font-semibold">Your Email:</label>
                                                    <input id="email" type="email" className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent rounded-full focus:border-violet-600 dark:focus:border-violet-600 focus:ring-0 mt-2" style={{background:'tranparent',border:'1px solid #fff'}} placeholder="Email :" />
                                                </div>
                                            </div>

                                            {/* <div className="mb-5">
                                                <div className="text-start">
                                                    <label htmlFor="comments" className="font-semibold">Your Comment:</label>
                                                    <textarea id="comments" className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent rounded-full focus:border-violet-600 dark:focus:border-violet-600 focus:ring-0 mt-2" style={{background:'tranparent',border:'1px solid #fff'}} placeholder="Message :"></textarea>
                                                </div>
                                            </div> */}
                                        </div>
                                        <button type="submit" id="submit" name="send" className="btn bg-transparent hover:bg-violet-600 border-violet-600 text-violet-600 hover:text-white rounded-full ms-2" >Submit</button>
                                    </form>
                    {/* <img src={logo_white} width={120} height={120} alt="Image Description" class="mx-auto mt-6"/> */}
                    {/* <h4 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-5xl mb-4" style={{color:'#fff'}}><span>Excel Academically with KBS </span>Your Premier Tuitions & Entrance Coaching Center</h4> */}
                    {/* <p className="max-w-xl mx-auto" style={{color:'#000'}}>Explore a marketplace where AI models are uniquely represented as NFTs for intuitive trading and creation.</p> */}
                    {/* <div className="mb-3">
                        <Link to="https://docs.google.com/forms/d/e/1FAIpQLSc-5YtQHmJiCb_krw_WCMLirRqtDJ6jPxd7nI7KY7wYrMpPWw/viewform?usp=sf_link" target="_blank" className="btn text-white rounded-full me-2 mt-2" style={{cursor:'pointer',background:'#c82325',color:'#fff',border:'1px solid #c82325'}}>Apply Now</Link>
                    </div> */}
                </div>

                </div>
            </section>
           
            <section className="relative pt-24 overflow-hidden" style={{ marginTop: '-20px',marginBottom:'70px' }}>
                <div className="container-fluid relative">
                    <div className="grid grid-cols-1">
                        <Slider {...settings}>
                            {Partners.map((image, index) => (
                                <div key={index} className="slide mx-2">
                                    <div className="group relative overflow-hidden" >
                                        <img src={image.image} style={{ objectFit: 'contain',cursor:'pointer',border:'1px solid #DDDDDD' ,margin:'0 6px',padding:'35px',}} className="transition-all duration-500 p-10" alt="" />
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </section>
            
            <FooterNew />
            {/* <Switcher /> */}
        </>
    )
}

// import React, { useState } from 'react';
// import DatePicker from 'react-datepicker';
// import addDays from 'date-fns/addDays';
// import "react-datepicker/dist/react-datepicker.css";

// const App = () => {
//   const [startDate, setStartDate] = useState(new Date());

//   const handleChange = (date) => {
//     setStartDate(date);
//   };

//   const onFormSubmit = (e) => {
//     e.preventDefault();
//     console.log(startDate);
//   };

//   return (
//     <form onSubmit={onFormSubmit}>
//       <div >
//         <DatePicker
//           selected={startDate}
//           onChange={handleChange}
//           showTimeSelect
//           timeFormat="HH:mm"
//           timeIntervals={20}
//           timeCaption="time"
//           dateFormat="MMMM d, yyyy h:mm aa"
//           minDate={new Date()}
//           maxDate={addDays(new Date())}
//         />
//         <button type="submit">Show Date</button>
//       </div>
//     </form>
//   );
// };

// export default App;
