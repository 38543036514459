import React, { useState, useEffect } from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import { Link } from 'react-router-dom';
import { MdOutlineArrowForward } from 'react-icons/md';
import general1 from '../../../../assets/images/royal-ashiq.jpg';
import general2 from '../../../../assets/images/royal-ashiq1.jpg';
import general3 from '../../../../assets/images/royal-ashiq2.jpg';
import general4 from '../../../../assets/images/royal-ashiq3.jpg';
import general5 from '../../../../assets/images/royal-ashiq4.jpg';
import general6 from '../../../../assets/images/royal-ashiq5.jpg';
import general7 from '../../../../assets/images/royal-ashiq6.jpg';
import general8 from '../../../../assets/images/royal-ashiq7.jpg';
import FooterNew from '../../../../components/footer-new';
import Navbar from '../../../../components/navbar';

function ThumbnailSlider({ images, selectedImage, onSelect, disabled }) {
  return (
    <div id="thumbnail_slider">
      <Splide
        options={{
          rewind: true,
          fixedWidth: 100,
          fixedHeight: 64,
          isNavigation: true,
          gap: 10,
          autoplay: true,
          interval: 2000, // 1 second autoplay speed
          focus: 'center',
          pagination: false,
          cover: true,
          disabled: disabled // Disable sliding when disabled is true
        }}
      >
        {images.map((item, index) => (
          <SplideSlide key={index}>
            <img
              src={item.src}
              alt={`Thumbnail ${index + 1}`}
              onClick={() => onSelect(item.src)} // Call onSelect with the image source
              className={selectedImage === item.src ? 'selected' : ''}
            />
          </SplideSlide>
        ))}
      </Splide>
    </div>
  );
}


function PrimarySlider({ images, selectedImage, autoplay }) {
  return (
    <div id="primary_slider">
      <Splide
        options={{
          type: 'fade',
          rewind: true,
          heightRatio: 0.5,
          pagination: false,
          arrows: false,
          cover: true,
          autoplay: autoplay,
          interval: 2000, // 1 second autoplay speed
        }}
      >
        {images.map((item, index) => (
          <SplideSlide key={index}>
            <img
              src={item.src}
              alt={`Image ${index + 1}`}
              style={{ display: selectedImage === item.src ? 'block' : 'none' }}
            />
          </SplideSlide>
        ))}
      </Splide>
    </div>
  );
}

export default function ArjunAshoken() {
  const [selectedImage, setSelectedImage] = useState(general1); // Initial selected image
  const [autoplay, setAutoplay] = useState(true); // Autoplay state

  const images = [
    { id: 1, src: general1, name: 'general1', category: 'Images' },
    { id: 2, src: general2, name: 'general2', category: 'Images' },
    { id: 3, src: general3, name: 'general3', category: 'Images' },
    { id: 4, src: general4, name: 'general4', category: 'Images' },
    { id: 5, src: general5, name: 'general5', category: 'Images' },
    { id: 6, src: general6, name: 'general6', category: 'Images' },
    { id: 7, src: general7, name: 'general7', category: 'Images' },
    { id: 8, src: general8, name: 'general8', category: 'Images' },
  ];

  const handleImageSelect = (imageSrc) => {
    if (!autoplay) {
      setSelectedImage(imageSrc);
      setAutoplay(false); // Pause autoplay when thumbnail clicked
    }
  };
  

  useEffect(() => {
    if (autoplay) {
      const interval = setInterval(() => {
        const currentIndex = images.findIndex((image) => image.src === selectedImage);
        const nextIndex = (currentIndex + 1) % images.length;
        setSelectedImage(images[nextIndex].src);
      }, 3000); // Change image every 3 seconds
      return () => clearInterval(interval);
    }
  }, [autoplay, selectedImage, images]);

  return (
    <>
      <div className="thumbnail_slider">
      <h4 className="slider-head-8">Dr Ashiq Wedding</h4>
        <PrimarySlider images={images} selectedImage={selectedImage} autoplay={autoplay} />
        <ThumbnailSlider images={images} selectedImage={selectedImage} onSelect={handleImageSelect} disabled={!autoplay} />
        <button type="submit" id="submit" name="send">
          <Link to="/inner-stories" className="btn bg-transparent hover:bg-violet-600 border-violet-600 text-violet-600 hover:text-white rounded-full ms-2" style={{display:"flex", alignItems: "center" }}>
            View More<MdOutlineArrowForward className="ms-1"/>
          </Link>
        </button>
      </div>
    </>
  );
}